import { faCheckCircle as icon } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import Snackbar from "@material-ui/core/Snackbar"
import SnackbarContent from "@material-ui/core/SnackbarContent"
import { graphql, navigate } from "gatsby"
import { useI18next } from "gatsby-plugin-react-i18next"
import React, { Fragment, useContext, useEffect, useState } from "react"
import Helmet from "react-helmet"
import styled from "styled-components"
import Container from "../../components/Container"
import CourseOptionsEditor from "../../components/user/CourseOptionsEditor"
import LoginStateContext, {
  withLoginStateContext,
} from "../../contexes/LoginStateContext"
import { getCachedUserDetails } from "../../services/moocfi"
import Layout from "../../templates/Layout"

const StyledSnackbarContent = styled(SnackbarContent)`
  background-color: #43a047 !important;
`

const StyledIcon = styled(FontAwesomeIcon)`
  margin-right: 0.5rem;
`

const MissingInfo = (props) => {
  const [state, setState] = useState({
    successMessage: null,
  })

  const context = useContext(LoginStateContext)
  const { language } = useI18next()

  const onStepComplete = () => {
    setState({ successMessage: "Saved!" })
  }

  const handleClose = () => {
    setState({ successMessage: null })
  }

  useEffect(() => {
    async function getUserDetailsAsync() {
      let userInfo = await getCachedUserDetails()
      const student_language = userInfo?.extra_fields?.language
      if (student_language === undefined) {
        navigate("/missing-info")
      }
    }
    getUserDetailsAsync()
  }, [language])
  if (!context?.loggedIn) {
    if (typeof window !== "undefined") {
      navigate("/sign-in")
    }
    return <div>Redirecting...</div>
  }

  return (
    <Layout>
      <Container>
        <Helmet title="Profile" />
        <CourseOptionsEditor onComplete={onStepComplete} />
      </Container>
      <Snackbar
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        open={state.successMessage}
        autoHideDuration={6000}
        onClose={handleClose}
      >
        <StyledSnackbarContent
          color="success"
          message={
            <Fragment>
              <StyledIcon icon={icon} /> <span>{state.successMessage}</span>
            </Fragment>
          }
        />
      </Snackbar>
    </Layout>
  )
}

export default withLoginStateContext(MissingInfo)

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`
